import Cookie from 'js-cookie'
// 401拦截
// const resp401 = {
//   onFulfilled(response, options) {
//     const {message} = options
//     if (response.data.code === 401) {
//       message.error(response.data.msg)
//     }
//     return response
//   },
//   onRejected(error, options) {
//     const {message} = options
//     const {response} = error
//     if (response.status === 401) {
//       message.error('无此权限')
//     }
//     return Promise.reject(error)
//   }
// }
//
// const resp403 = {
//   onFulfilled(response, options) {
//     const {message} = options
//     if (response.code === 403) {
//       message.error('请求被拒绝')
//     }
//     return response
//   },
//   onRejected(error, options) {
//     const {message} = options
//     const {response} = error
//     console.log(response)
//     if (response.status === 403) {
//       message.error('请求被拒绝')
//     }
//     return Promise.reject(error)
//   }
// }
//token失效
const resp400 = {
  onFulfilled(response, options) {
    const {message} = options

    if (response.data.code === 400) {
      Cookie.set(process.env.VUE_APP_USER_INFO,null,{expires:1,path:'/',domain:process.env.VUE_APP_DOMAIN})
      location.reload();
      //message.fail(response.data.msg)
      return response
    }else{
      return response
    }
  },
  onRejected(error, options) {
    // const {message} = options
    const {response} = error
    if (response.status === 400) {
      Cookie.set(process.env.VUE_APP_USER_INFO,null,{expires:1,path:'/',domain:process.env.VUE_APP_DOMAIN})
      location.reload();
      // message.fail(response.data.msg)
    }
    return Promise.reject(error)
  }
}
const reqCommon = {
  onFulfilled(config, options) {
    const {message} = options
    const {url, xsrfCookieName} = config
    if (url.indexOf('/user/') !== -1 && xsrfCookieName && !Cookie.get(xsrfCookieName)) {
      Cookie.set(process.env.VUE_APP_USER_INFO,null,{expires:1,path:'/',domain:process.env.VUE_APP_DOMAIN})
      location.reload();
      // message.fail('认证 token 已过期，请重新登录')
    }
    return config
  },
  /**
   * 请求出错时做点什么
   * @param error 错误对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {Promise<never>}
   */
  onRejected(error, options) {
    const {message} = options
    message.fail(error.message)
    return Promise.reject(error)
  }
}
export default {
  request: [reqCommon], // 请求拦截
  response: [resp400] // 响应拦截
}

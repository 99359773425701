import {
  userinfo,jsapiSignature
} from './api'
import {request, METHOD} from '@/utils/request'
//授权
export async function GetuserInfo(params) {
  return request(userinfo, METHOD.POST,params)
}

export async function GetjsapiSignature(params) {
  return request(jsapiSignature, METHOD.POST,params)
}


import Vue from 'vue'
import Vuex from 'vuex'
import Cookie from 'js-cookie'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
		userInfo:null,
    hdInfo:null,
    hduserInfo:null,
    snapshotuser:0
  },
  getters: {
    userInfo:state=>{
      if (state.userInfo==null) {
        try {
          if(Cookie.get(process.env.VUE_APP_USER_INFO)){
            const userInfo = Cookie.get(process.env.VUE_APP_USER_INFO)
            state.userInfo = JSON.parse(userInfo)
          }
        } catch (e) {
          console.error(e)
        }
      }
      return state.userInfo
    },
    hdInfo:state=>{
      if (!state.hdInfo) {
        try {
          const hdInfo = sessionStorage.getItem(process.env.VUE_APP_HD_INFO)
          state.hdInfo = JSON.parse(hdInfo)
        } catch (e) {
          console.error(e)
        }
      }
      return state.hdInfo
    },
    hduserInfo:state=>{
      if (!state.hduserInfo) {
        try {
          const hduserInfo = sessionStorage.getItem(process.env.VUE_APP_HD_USER_INFO)
          state.hduserInfo = JSON.parse(hduserInfo)
        } catch (e) {
          console.error(e)
        }
      }
      return state.hduserInfo
    },
    snapshotuser:state=>{

      if (!state.snapshotuser) {
        try {
          const snapshotuser = sessionStorage.getItem('snapshotuser')
          state.snapshotuser = snapshotuser==null?state.snapshotuser:snapshotuser
        } catch (e) {
          console.error(e)
        }

      }

      return state.snapshotuser
    }
  },
  mutations: {
    setuserInfo(state,value){
      state.userInfo = value
      Cookie.set(process.env.VUE_APP_USER_INFO,JSON.stringify(value), {expires:30,path:'/',domain:process.env.VUE_APP_DOMAIN})
    },
    sethdInfo(state,value){
      state.hdInfo = value
      sessionStorage.setItem(process.env.VUE_APP_HD_INFO, JSON.stringify(value))
    },
    sethduserInfo(state,value){
      state.hduserInfo = value
      sessionStorage.setItem(process.env.VUE_APP_HD_USER_INFO, JSON.stringify(value))
    },
    setsnapshotuser(state,value){
      state.snapshotuser = value
      sessionStorage.setItem('snapshotuser', value)
    },
  },
  actions: {

  }
})

import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
//用户中心
const ucenterMgt=[
  {
    path: '/ucenter',
    name:'会员中心',
    meta: {
      keepAlive: false
    },
    component: () => import('@/pages/ucenter/index.vue')
  }
]
//微信活动
const wxMgt=[
  {
    path: '/wx/:hdid',
    name: '活动',
    meta: {
      keepAlive: true
    },
    component: () => import('@/pages/wx/index.vue')
  },
  {
    path: '/wx/cart/:pid',
    name: '购物车',
    meta: {
      keepAlive: false
    },
    component: () => import('@/pages/wx/cart.vue')
  },
  {
    path: '/wx/prize/:hdid',
    name: '中奖记录',
    meta: {
      keepAlive: false
    },
    component: () => import('@/pages/wx/cj/zhongjiang.vue')
  },
  {
    path: '/wx/commission/:hdid',
    name: '我的奖励',
    meta: {
      keepAlive: false
    },
    component: () => import('@/pages/wx/commission.vue')
  },
  {
    path: '/wx/cash/:hdid',
    name: '提现记录',
    meta: {
      keepAlive: false
    },
    component: () => import('@/pages/wx/cash.vue')
  },
  {
    path: '/wx/order/:hdid',
    name: '我的订单',
    meta: {
      keepAlive: false
    },
    component: () => import('@/pages/wx/order.vue')
  },
  {
    path: '/wx/order/detail/:orderid',
    name: '订单详情',
    meta: {
      keepAlive: false
    },
    component: () => import('@/pages/wx/order_detail.vue')
  },
  {
    path: '/wx/scan/hx/:orderid',
    name: '扫码核销',
    meta: {
      keepAlive: false
    },
    component: () => import('@/pages/wx/scan_hx.vue')
  }
]
//抖音活动
const douyinMgt=[
  {
    path: '/douyin/:hdid',
    name: '活动',
    meta: {
      keepAlive: true
    },
    component: () => import('@/pages/douyin/index.vue')
  },
  {
    path: '/douyin/order/:hdid',
    name: '我的订单',
    meta: {
      keepAlive: false
    },
    component: () => import('@/pages/douyin/order.vue')
  },
  {
    path: '/douyin/order/detail/:orderid',
    name: '订单详情',
    meta: {
      keepAlive: false
    },
    component: () => import('@/pages/douyin/order_detail.vue')
  }
]

const routes = [
  ...ucenterMgt,
  ...wxMgt,
  ...douyinMgt,
  {
    path: '/wx/complaint',
    name: '投诉',
    meta: {
      keepAlive: false
    },
    component: () => import('@/pages/complaint.vue')
  },
  {
    path: '/finish',
    name: '活动已结束',
    meta: {
      keepAlive: false
    },
    component: () => import('@/pages/finish.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})
// router.beforeEach( (to,from,next) => {
//   if(to.meta.title){
//     document.title = to.meta.title
//   }
//   next()
// })
export default router

<template>
<div></div>
</template>
<script>
  import {getQueryString,delQueryString} from '@/utils/util'
  import {setAuthorization} from '@/utils/request'
  import {GetuserInfo} from '@/services/services'
  import { mapGetters, mapMutations } from 'vuex'
  export default {
    name: 'common',
    components: {},
    data () {
      return {
        AUTH_URL:process.env.VUE_APP_AUTH_URL,
        source_key:1,
      }
    },
    created(){
	   
    },
    computed:{
      ...mapGetters(['userInfo','snapshotuser']),
    },
    watch:{},
    mounted(){
      let href=window.location.href
      if(href.indexOf('douyin/') === -1){
        this.source_key=1
      }else{
        this.source_key=2
      }
      let url=location.href
      if(url.indexOf('mobilehtml') === -1){
        console.log(this.snapshotuser)
        if(this.userInfo == null && this.snapshotuser == 0){
          let code = getQueryString('code')
          if(code==undefined){
            this.getCode()
          }else{
            this.finduserInfo(code)
          }
        }else{
          if(getQueryString('code') != undefined){
            location.href = delQueryString(location.href,'code')
          }
        }
      }
    },
    methods:{
      ...mapMutations(["setuserInfo","setsnapshotuser"]),
      getCode(){
        let href=window.location.href
        if(href.indexOf('douyin/') === -1){
          let appid=process.env.VUE_APP_APPID
          let url=this.AUTH_URL+location.pathname+location.search
          location.href ="https://open.weixin.qq.com/connect/oauth2/authorize?appid="+appid+"&redirect_uri="+url+"&response_type=code&scope=snsapi_userinfo&state=234&forcePopup=true&forceSnapShot=true#wechat_redirect"
        }else{
          let client_key=process.env.VUE_APP_DYAPPID
          let scope='user_info,mobile_alert,h5.share,open.get.ticket,video.data';
          location.href = "https://open.douyin.com/platform/oauth/connect/?client_key="+client_key+"&redirect_uri="+href+"&response_type=code&scope="+scope+"&state=1";
        }
      },
      finduserInfo(code){
        let host=location.host
        GetuserInfo({code:code,host:host,source_key:this.source_key}).then(result => {
          if(result.data.code==1) {
            const {data,token,is_snapshotuser} =result.data.data
            if(token == ''){
              this.setsnapshotuser(is_snapshotuser)
              this.setuserInfo(null)
              setAuthorization({token:''})
            }else{
              const {avatarUrl,id,nickName,openId}=data
              this.setuserInfo({avatarUrl,id,nickName,openId})
              setAuthorization({token:token})
	            if(getQueryString('code') != undefined){
                location.href = delQueryString(location.href,'code')
	            }
            }
          }else{
            this.$toast(result.data.msg)
          }
        })
      }
    }
  }
</script>
<style lang="less">
</style>


<template>
  <div id="app">
    <template v-if="isMobile">
      <template v-if="(hd_type==1 && isWeixin) ||　hd_type==2">
        <template v-if="view_show">
          <keep-alive>
            <router-view v-wechat-title='$route.name' v-if="$route.meta.keepAlive"/>
          </keep-alive>
          <router-view v-wechat-title='$route.name' v-if="!$route.meta.keepAlive"/>
        </template>
        <van-overlay :show="snapshotuser == 1?true:false" z-index="9999">
          <span class="snapshotuser">点击右下角“使用完整服务” <van-icon class-prefix="ico" name="zhuanfa" size="18"/></span>
        </van-overlay>
        <common/>
      </template>
      <div class="defaultPage" v-if="hd_type==1 && !isWeixin">
        <p><van-icon class-prefix="ico" name="weixin1" color="#07c160" size="2rem"/></p>
        <p class="font-color-gray mt20">请在微信客户端打开链接</p>
      </div>
    </template>
    <div class="defaultPage" v-else>
      <p><van-icon class-prefix="ico" name="wap" color="#07c160" size="2rem" /></p>
      <p class="font-color-gray mt20">请在手机端打开链接</p>
    </div>
  </div>
</template>
<script>
  import {isMobile,isWeixin} from '@/utils/util'
  import common from '@/components/common'
  import {mapGetters} from "vuex";
  export default {
    components: {common},
    data () {
      return {
        isMobile:true,
        isWeixin:true,
        hd_type:1,//1微信，2抖音
      }
    },
    computed:{
      ...mapGetters(['userInfo','snapshotuser']),
      view_show(){
        let url=window.location.href
        if((this.userInfo != null && this.snapshotuser == 0) || url.indexOf('mobilehtml') !== -1 || this.snapshotuser == 1){
          return true
        }else{
          return false
        }
      }
    },
    watch:{

    },
    mounted(){

    },
    created(){
      this.isMobile = isMobile()?1:0
      this.isWeixin = process.env.VUE_APP_PRODUCTION==0?1:(isWeixin()?1:0)
      let url=window.location.href
      this.hd_type = url.indexOf('/wx/')===-1?2:1

    },
    methods: {

    
    }
  }
</script>
<style lang="less">
  @import "@/assets/css/base.less";
  @import "@/assets/css/global.less";
  .defaultPage{text-align:center;padding-top:60px;}
  .snapshotuser{background:#000;color:#fff;border-radius:20px;padding:6px 20px 9px 20px;display:inline-block;position:absolute;right:20px;z-index:1;animation:jump 1s ease-in-out infinite alternate;box-shadow:0px 0px 2px 2px rgba(0,0,0,0.3);
    /*&::after{content:"";height: 0;width: 0;position:absolute;bottom:-12px;right:10%;transform:translateX(-50%);border:6px solid;border-color:#000 transparent transparent transparent;}*/
  }
  @keyframes jump{
    0%{bottom:15px;}
    30%{bottom:10px;}
    60%{transform:rotate(4deg)}
    90%{transform:rotate(0deg)}
    100%{bottom:15px;}
  }
</style>
